import React from 'react';
import '../styles/Components.css';

const ContactPopup = ({ showPopup, togglePopup }) => {
  return (
    <div className={`contact-popup ${showPopup ? 'active' : ''}`}>
      <button className="close-popup" onClick={togglePopup}>
        &times;
      </button>
      <h2>Contact Me</h2>
      <div className="contact-container">
        <div className="contact-info">
          <p><i className="fas fa-envelope"></i> Email: <a href="mailto:widmisonfrancois1@gmail.com">widmisonfrancois1@gmail.com</a></p>
          <p><i className="fas fa-phone"></i> Phone: <a href="tel:+12396519228">239-651-9228</a></p>
          <p><i className="fas fa-map-marker-alt"></i> Location: West Palm Beach, FL 33411</p>
        </div>
        <div className="social-media">
          <a href="https://www.linkedin.com/in/widmison-francois-023185164/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
          <a href="https://www.facebook.com/yourprofile" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
          <a href="https://www.instagram.com/widmison/?hl=en" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://github.com/Widmison" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
        </div>
      </div>
    </div>
  );
};

export default ContactPopup;
