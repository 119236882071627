import React from 'react';
import '../styles/Components.css';

const Header = () => {
  const toggleMenu = () => {
    const navContainer = document.querySelector('.nav-container');
    navContainer.classList.toggle('active');
    const closeMenuButton = document.querySelector('.close-menu');
    closeMenuButton.classList.toggle('active');
  };

  return (
    <header>
      <div className="header-container">
        <button className="hamburger" onClick={toggleMenu}>
          <i className="fas fa-bars"></i>
        </button>
        <div className="about">
          <h1>Widmison Francois</h1>
          <h2>Web & Software Developer</h2>
        </div>
      </div>
      <nav className="nav-container">
        <button className="close-menu" onClick={toggleMenu}>
          <i className="fas fa-times"></i>
        </button>
        <ul className="nav-links">
          <li><a href="#hero">Home</a></li>
          <li><a href="#skills">Skills</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#contact" className="contact-button">Contact</a></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
