import React from 'react';
import '../styles/Components.css';

const Skills = () => {
  return (
    <section id="skills">
    <h2>My Skills</h2>
    <div class="skills-container">
        <div class="skill-card">
            <h3>Languages</h3>
            <ul>
                <li>JavaScript (ES6+)</li>
                <li>Python</li>
                <li>Java</li>
                <li>C#</li>
                <li>Ruby</li>
                <li>PHP</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>Front-End</h3>
            <ul>
                <li>HTML5</li>
                <li>CSS3</li>
                <li>Sass</li>
                <li>React</li>
                <li>Vue.js</li>
                <li>Angular</li>
                <li>Bootstrap</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>Back-End</h3>
            <ul>
                <li>Node.js</li>
                <li>Express.js</li>
                <li>Ruby on Rails</li>
                <li>Spring Boot</li>
                <li>Laravel</li>
                <li>ASP.NET Core</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>Database</h3>
            <ul>
                <li>SQL</li>
                <li>MySQL</li>
                <li>PostgreSQL</li>
                <li>MongoDB</li>
                <li>SQLite</li>
                <li>Firebase</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>DevTools</h3>
            <ul>
                <li>Git & GitHub</li>
                <li>Docker</li>
                <li>Kubernetes</li>
                <li>CI/CD</li>
                <li>AWS</li>
                <li>Azure</li>
                <li>Google Cloud</li>
                <li>Jenkins</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>Debugging</h3>
            <ul>
                <li>Unit-Testing</li>
                <li>Integration-Testing</li>
                <li>Jest</li>
                <li>Mocha</li>
                <li>Chai</li>
                <li>Selenium</li>
                <li>Postman</li>
            </ul>
        </div>
        <div class="skill-card">
            <h3>Soft Skills</h3>
            <ul>
                <li>Problem-Solving</li>
                <li>Team Collaboration</li>
                <li>Communication</li>
                <li>Time Management</li>
                <li>Adaptability</li>
                <li>Critical Thinking</li>
            </ul>
        </div>
    </div>
</section>
  );
};

export default Skills;
