import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // For SEO
import '../styles/Components.css';
import beyooImage from '../assets/beyoo-store-homepage.png';
import royolImage from '../assets/royol-homepage.png';
import gjpImage from '../assets/gjpcover.png';
import caribbenImage from '../assets/caribbe-n.png';

const Projects = () => {
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const projectsPerPage = 2;

  const projects = [
    {
      id: 'details-1',
      img: beyooImage,
      title: 'Beyoo Magic Online Store',
      description: 'Beyoo Magic is an e-commerce platform.',
      responsibilities: 'Set up Shopify store, customized the theme, and developed custom features to enhance user experience.',
      technologies: ['Liquid', 'HTML/CSS', 'JavaScript', 'Shopify APIs', 'Mailchimp', 'Yotpo Reviews', 'Shopify Payments'],
      features: ['Custom Theme', 'Product Filters', 'Custom Checkout Flow', 'Third-Party Integrations'],
    },
    {
      id: 'details-2',
      img: royolImage,
      title: 'Royol Technology',
      description: 'Royol Technology specializes in professional web and app development services.',
      responsibilities: 'Full-stack development of website and mobile apps, including frontend and backend, with third-party API integration.',
      technologies: ['HTML/CSS', 'JavaScript', 'PHP', 'PayPal API', 'Facebook API', 'React Native', 'Flutter'],
      features: ['Responsive Design', 'E-commerce Integration', 'Advanced SEO', 'Custom Integrations', 'Mobile App Development'],
    },
    {
      id: 'details-3',
      img: gjpImage,
      title: 'GJP Pro Online Store',
      description: 'Custom-built e-commerce platform for professional-grade products and services.',
      responsibilities: 'Full-stack development with features like responsive design and social media integration.',
      technologies: ['HTML/CSS', 'JavaScript', 'PHP', 'PayPal API', 'Facebook API'],
      features: ['Responsive Design', 'Product Filters', 'Custom Checkout Flow', 'Social Media Integration'],
    },
    {
      id: 'details-4',
      img: caribbenImage,
      title: 'Caribbe-n Online Store',
      description: 'E-commerce platform offering stylish and affordable swimwear.',
      responsibilities: 'UI/UX design, React frontend, REST API backend integration, and payment gateway setup.',
      technologies: ['React', 'CSS', 'REST API', 'Payment Gateway Integration'],
      features: ['Responsive Design', 'Product Search', 'User Authentication', 'Payment Integration'],
    },
  ];

  const navigateProjects = (direction) => {
    const totalProjects = projects.length;
    const newIndex = Math.min(
      Math.max(currentProjectIndex + direction * projectsPerPage, 0),
      totalProjects - projectsPerPage
    );
    setCurrentProjectIndex(newIndex);
  };

  return (
    <section id="projects">
      <Helmet>
        <title>My Projects - Widmison Francois</title>
        <meta
          name="description"
          content="Explore innovative projects by Widmison Francois, including e-commerce platforms and professional web development."
        />
      </Helmet>
      <h2>My Projects</h2>
      <div className="projects">
        {projects.slice(currentProjectIndex, currentProjectIndex + projectsPerPage).map((project) => (
          <article className="project" key={project.id}>
            <img
              src={project.img}
              alt={`${project.title} screenshot`}
              className="project-image"
              loading="lazy"
            />
            <div className="project-details">
              <h3>{project.title}</h3>
              <p className="description">{project.description}</p>
              <h4>Role and Responsibilities</h4>
              <p>{project.responsibilities}</p>
              <h4>Technologies and Tools</h4>
              <ul>
                {project.technologies.map((tech) => (
                  <li key={tech}>{tech}</li>
                ))}
              </ul>
              <h4>Features</h4>
              <ul>
                {project.features.map((feature) => (
                  <li key={feature}>{feature}</li>
                ))}
              </ul>
            </div>
          </article>
        ))}
      </div>
      <div className="navigation-buttons">
        <button
          onClick={() => navigateProjects(-1)}
          disabled={currentProjectIndex === 0}
          aria-label="Previous Projects"
        >
          Prev
        </button>
        <button
          onClick={() => navigateProjects(1)}
          disabled={currentProjectIndex + projectsPerPage >= projects.length}
          aria-label="Next Projects"
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default Projects;
