import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import './styles/App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ContactPopup from './components/ContactPopup';

const App = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="App">
      <Helmet>
        <title>Widmison Francois - Web & Software Developer</title>
        <meta name="description" content="Portfolio of Widmison Francois, a skilled Web and Software Developer showcasing projects, skills, and contact information." />
        <meta name="keywords" content="Web Developer, Software Developer, Portfolio, React, Projects, Contact" />
        <meta name="author" content="Widmison Francois" />
        <meta property="og:title" content="Widmison Francois - Web & Software Developer" />
        <meta property="og:description" content="Explore the portfolio of Widmison Francois, featuring projects and skills in web and software development." />
        <meta property="og:image" content="https://widmisonfrancois.com/hero.jpg" />
        <meta property="og:url" content="https://widmisonfrancois.com/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <Hero />
      <Skills />
      <Projects />
      <Contact />
      <Footer />

      <button className="get-in-touch-btn" onClick={togglePopup}>
        Get in Touch
      </button>

      <ContactPopup showPopup={showPopup} togglePopup={togglePopup} />
    </div>
  );
};

export default App;
