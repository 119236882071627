import React from 'react';
import heroImage from '../assets/hero.jpg';
import placeholderImage from '../assets/hero-1.png'; // Import your new placeholder image
import '../styles/Components.css';

const Hero = () => (
  <section id="hero">
    
    
    <div className="hero-text">
    <img src={heroImage} alt="Widmison Francois" className="hero-image" />
      <h1 className="hero-heading"></h1>
      <p className="intro">Hey, my name is Widmison Francois.
        I'm an accomplished Web and Software Developer with extensive experience in building innovative software applications and websites.
      </p>
      <a href="#projects" className="cta-button">View My Projects</a>
    </div>

    {/* New Image Placeholder Div */}
    <div className="hero-placeholder-image">
      <img src={placeholderImage} alt="Placeholder" />
    </div>
  </section>
);

export default Hero;
